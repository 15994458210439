.news {
  background: $color-primary-lighten;
  padding: $space-4 0;
  margin-top: 1px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url("../assets/site/pattern-form.webp") repeat;
    mix-blend-mode: multiply;
    background-blend-mode: color-burn;
    opacity: 0.6;
  }
  &__container {
    position: relative;
    display: flex;
    gap: $space-3;
    justify-content: center;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__claim {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 40%;
  }

  &__subtitle {
    font-size: $header-5;
    text-transform: uppercase;
    margin-bottom: $space-1;
    color: $color-primary;
  }

  &__title {
    font-family: $typo-secondary;
    font-size: $header-1;
    margin-bottom: $space-2;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
  }

  &__discount {
  }

  &__form {
  }
}

.tnp-subscription {
  background: $color-white;
  box-shadow: $box-shadow;
  padding: $space-2;
  border-radius: $border-radius;
  @media screen and (max-width: 767px) {
    padding: $space-1;
  }
  .tnp-field-privacy {
    a {
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      color: $color-grey-3;
      u {
        color: $color-primary;
      }
    }
  }
  .tnp-field-button {
    center {
      text-align: left;
      .tnp-submit {
        border-radius: 50px;
        padding: 10px $font-size-big;
      }
    }
  }
}
