.scats {
  overflow: hidden;
  padding-bottom: 25px;
  .slick-dots {
    bottom: $font-size-smaller;
    li {
      button {
        &::before {
          font-size: $font-size-smaller !important;
        }
      }
    }
  }
  &__item {
    margin-left: $font-size-smaller;
    margin-right: $font-size-smaller;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      margin-left: $font-size / 2;
      margin-right: $font-size / 2;
    }
  }

  &__titulo {
    display: inline-flex;
    justify-content: center;
    font-family: $typo-secondary;
    font-size: $header-3;
    color: $color-grey-2;
    background: $color-white;
    top: -2.6rem;
    position: relative;
    z-index: 2;
    padding: $font-size-smaller $font-size-big;
    box-shadow: $box-shadow;
    // text-transform: uppercase;
    // border-radius: 50px;
  }

  &__imagen {
    width: 100%;
    margin-bottom: $font-size;
    position: relative;
    aspect-ratio: 4 / 3;
    // border: 1px solid red;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  &__novedad {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: $font-size-smaller;
    top: $font-size-smaller;
    span {
      padding: 0.1rem 0.6rem;
      background-color: $color-accent;
      border-radius: 50px;
      color: $color-white;
      font-size: $font-size-small;
    }
  }

  &__categoria {
    display: flex;
    justify-content: center;
    font-family: $typo-secondary;
  }
}

.porque {
  display: flex;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
  &__cover {
    flex: 0 0 40%;
    height: 100%;
    position: relative;
    padding-top: $space-3;
    @media screen and (max-width: 991px) {
      padding-top: 0;
    }
    img {
      // position: absolute;
      // left: 0;
      // right: 0;
      // top: 0;
      // bottom: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        height: 200px;
      }
    }
  }
  &__items {
    display: flex;
    gap: $space-1;
    flex-direction: column;
    justify-content: center;
    padding: $space-3 $space-3;
    @media screen and (max-width: 767px) {
      padding: $space-1;
    }
  }

  &__item {
    display: flex;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      gap: $space-1;
    }
  }
  &__ico {
    .bi {
      font-size: 2rem;
      margin-top: 20px;
      color: $color-primary-light;
      // color: $color-accent;
    }
  }
  &__titulo {
    margin-top: 0;
    font-size: $header-2;
    margin-bottom: $space-1;
    font-family: $typo-secondary;
    color: $color-primary-light;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }
  &__descripcion {
    color: $color-white;
  }
}

.autora {
  padding: $space-4 0;
  background: $color-grey-6;
  &__container {
    display: flex;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-2;
    }
  }

  &__picture {
    flex: 0 0 40%;
    img {
      width: 100%;
      height: auto;
      filter: grayscale(1);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__name {
    color: $color-primary;
    margin-bottom: $space-1;
    font-size: $header-5;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__titulo {
    margin-bottom: $space-2;
    font-size: $header-2;
    font-family: $typo-secondary;
  }

  &__descripcion {
  }

  &__actions {
    margin-top: $space-1;
  }
}

.colecciones {
  padding: $space-3 0 $space-4;
  background-color: $color-grey-6;
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3px;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    position: relative;
  }

  &__title {
    position: absolute;
    bottom: $font-size;
    left: $font-size;
    font-family: $typo-secondary;
    color: $color-white;
    font-size: $header-3;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }

  &__cover {
    img {
      aspect-ratio: 1;
      width: 100%;
      height: auto;
      @media screen and (max-width: 767px) {
        aspect-ratio: 16 / 11;
        object-fit: cover;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      @media screen and (max-width: 767px) {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.43) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
}
