.woof_container_inner {
  & > h4 {
    font-size: $font-size-small;
    color: $color-primary-dark;
  }
}

.woof_redraw_zone {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: $space-1;

  .woof_container {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .woof_submit_search_form_container {
    margin: 0;
    .button.woof_reset_search_form {
      padding: 0.35rem 1.3rem;
      background-color: $color-grey-3;
    }
  }
  .woof_list.woof_list_label {
    li {
      .woof_label_term {
        height: auto;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        border-radius: 4px;
        transition: $trans;
        &.checked {
          background-color: $color-primary;
          border-color: $color-primary;
        }
        &:hover {
          background-color: $color-primary;
          color: $color-white;
          border-color: $color-primary;
          transition: $trans;
        }
      }
      .woof_label_count {
        background: $color-primary-dark;
        color: $color-white;
        border-color: $color-primary-dark;
      }
    }
  }
}

.woof_products_top_panel {
  display: flex;
  align-items: center;
  gap: $font-size;
  .woof_reset_button_2 {
    font-size: $font-size-smaller;
    padding: 0.1rem 1rem !important;
    background-color: $color-grey-3;
    border: 0;
    color: $color-white;
    border-radius: 50px;
    &:hover {
      background: $color-grey-2;
    }
  }

  &_ul {
    ul {
      li {
        font-size: $font-size-small !important;
        a.woof_remove_ppi {
          font-weight: $font-weight-medium;
        }
      }
    }
  }
}
