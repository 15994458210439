.post-blog {
  @media screen and (min-width: 992px) {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
  }
  &__meta {
    border-top: 1px dashed $color-grey-4;
    padding-top: $space-1;
    margin-top: $space-2;
  }
}
