.content-sobre {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-4;
  @media screen and (max-width: 1399px) {
    gap: $space-3;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__col {
  }
  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-2;
    margin-bottom: $space-2;
    color: $color-primary;
  }
}
