.qib-button-wrapper {
  // margin: 0;
  margin-right: $font-size !important;
  display: flex !important;
  gap: 0 !important;
  border-radius: 6px !important;
  overflow: hidden;
  border: 1px dashed $color-grey-4;
  & > .qib-button {
    background-color: $color-grey-6;
    border: none;
    padding: 4px 10px 6px !important;
    transition: $trans;
    &:hover {
      background-color: $color-grey-4;
      cursor: pointer;
      color: $color-white;
      transition: $trans;
    }
    &.minus {
    }
    &.plus {
    }
  }
  .wqpmb_quantity {
    border-left: 0;
    border-right: 0;
    .qty {
      font-size: $font-size !important;
      width: 50px;
    }
  }
}
