.page-title {
  padding: $space-1 0;
  text-align: center;
  background-color: $color-primary-lighten;
  &__h {
    font-size: $display-2;
    font-family: $typo-secondary;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-smaller;
    a {
      color: $color-primary;
    }
  }
}

.single-product {
  padding-bottom: $font-size;

  .page-title {
    text-align: initial;
    &__h {
      display: none;
    }
    &__breadcrumbs {
    }
  }
}
