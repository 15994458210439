.widget {
  &__title {
    margin-bottom: 1.3rem;
    font-size: 1.2rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.6rem;
      font-size: 1rem;
    }
  }
}

.widget--page {
  @media screen and (max-width: 575px) {
    margin-bottom: 2rem !important;
  }
  .widget__title {
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
  .product-categories,
  .menu {
    list-style: none;
    padding-left: 0rem;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      @media screen and (max-width: 767px) {
        margin-right: 8px;
        margin-bottom: 8px;
      }
      a {
        @media screen and (max-width: 767px) {
          padding: 1px 6px;
          background-color: $color-grey-3;
          color: $color-background;
          display: inline-flex;
          border-radius: 50px;
          font-size: $font-size-smaller;
        }
      }
    }
    li.current-menu-item {
      a {
        color: $color-primary;
        font-weight: bold;
        @media screen and (max-width: 767px) {
          background-color: $color-primary;
          color: $color-background;
          font-weight: normal;
        }
      }
    }
  }
}
