.header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: $trans;
  position: sticky;
  top: 0;
  background-color: $color-grey-6;
  background-color: $color-primary-lighten;
  width: 100%;
  z-index: 99;

  @media screen and (max-width: 1419px) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  @media screen and (max-width: 1199px) {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }

  &__burger {
    margin-right: $font-size-big;
    img {
      width: 30px;
      height: auto;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: auto;
    margin-right: 2rem;
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;

    .header-logo {
      width: 120px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 1199px) {
        width: 100px;
      }
    }
  }
  &__right {
    // margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 1199px) {
      margin-left: auto;
    }

    &__item {
      margin-left: 1rem;
      img {
        width: auto;
        height: 25px;
      }
      a {
        transition: $trans;
        &:hover {
          transition: $trans;
        }
      }
    }
  }
}

.page-template-page-checkout,
.page-template-page-carrito {
  .header__right__item--carrito {
    display: none;
  }
}

.header-checkout {
  background-color: $color-primary-lighten;
  display: flex;
  justify-content: center;
  padding: $font-size 0;
  @media screen and (max-width: 1199px) {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  &__logo {
    .header-checkout-logo {
      width: 120px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 100px;
      }
    }
  }
}

.woocommerce-demo-store p.demo_store {
  position: fixed;
  height: auto;
  line-height: 19px;
}

.woocommerce-store-notice,
p.demo_store {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: $font-size-small;
  padding: 1em 0;
  text-align: center;
  background-color: $color-accent;
  color: $color-white;
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none;
  a {
    color: $color-white;
    text-decoration: underline;
  }
}

.woocommerce-store-notice__dismiss-link {
  font-weight: $font-weight-bold;
}

body {
  &.woocommerce-demo-store {
    margin-top: 45px !important;
    .header {
      top: 45px;
    }
  }
  &.admin-bar {
    p.demo_store {
      margin-top: 32px;
    }
  }
}
