.header {
  &__menu {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    .menu-item {
      padding: 10px 1rem;
      a {
        font-weight: 500;
        color: $color-primary-dark;
        &:hover {
          color: $color-primary;
        }
      }
    }
    .menu-item.menu-item-5074 {
      a {
        color: $color-accent;
      }
    }
    .current-menu-item.menu-item {
      a {
        color: $color-primary;
      }
    }
  }
}

.menu-off-separator {
  margin-bottom: $font-size;
  padding-bottom: $font-size;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
}

.offcanvas-section {
  @extend .menu-off-separator;
}

.menu-off {
  @extend .menu-off-separator;
  padding-left: $space-1;
  &__nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .menu-item {
    margin-bottom: $font-size-smaller / 2;
    a {
      font-size: $header-4;
      cursor: pointer;
      color: $color-white;
      a:hover {
        color: $color-white;
      }
    }
  }
}

.offcanvas {
  .offcanvas-header {
    padding: 1.2rem 1rem;
    background: $color-primary-lighten !important;
    text-transform: uppercase;
    h5 {
      font-size: $header-5;
      color: $color-primary;
    }
    .btn-close {
      // filter: brightness(0) invert(1);
    }
  }
}

.offcanvas.offcanvas-dark {
  background-color: $color-primary-dark;
  .offcanvas-header {
    background-color: $color-primary-dark !important;
    .btn-close {
      filter: brightness(0) invert(1);
    }
  }
}

.custom {
  display: flex;
  gap: $font-size;
  padding-right: $space-2;
  &__ico {
    font-size: $header-4;
    color: $color-white;
  }

  &__content {
    h2 {
      color: $color-white;
      font-size: $header-4;
    }
  }
}
