.footer {
  background-color: $color-primary;
  color: $color-white;
  padding: $space-3 0 $space-4;
  &__cols {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $space-2;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: $space-2;
    }
  }

  &__col {
    // margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
    &--logo {
      @media screen and (max-width: 767px) {
        // grid-column: 1 / 3;
      }
    }
    &--help {
      @media screen and (max-width: 767px) {
        .boton {
          width: 100%;
          text-align: center;
          display: block;
        }
      }
    }
    &--social {
      @media screen and (max-width: 767px) {
        display: flex;
        gap: $space-1;
      }
    }
  }
  &__pago {
    padding-top: $space-1;
    padding-bottom: $space-1;
    border-top: 1px dashed $color-white;
    border-bottom: 1px dashed $color-white;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      padding-top: $space-1 / 2;
      padding-bottom: $space-1 / 2;
    }
  }
  &__copy {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-smaller;
    gap: $font-size;
    @media screen and (max-width: 767px) {
    }
  }
  &__copy-copy {
  }
  &__nav-legal {
    @media screen and (max-width: 767px) {
      text-align: center;
      width: 100%;
    }
  }
}

.logos-pago {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $space-2;
  @media screen and (max-width: 767px) {
    gap: $font-size;
  }
  &__item {
    img {
      max-height: 20px;
      max-width: auto;
      filter: brightness(0) invert(1);
      @media screen and (max-width: 767px) {
        max-height: 15px;
      }
    }
  }
}

.widget-footer {
  &__title {
    text-transform: none;
    font-size: $header-3;
    margin-bottom: $space-1;
    font-family: $typo-secondary;
    // color: $color-dark;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size;
    }
  }
}

.widget-footer {
  margin-bottom: $space-2;
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
  .textwidget {
    p {
      font-size: $font-size-small;
    }
  }
  ul.menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    li {
      font-size: $font-size-small;
      padding: 4px 0;
      @media screen and (max-width: 767px) {
        padding: 0;
      }
      a {
        color: $color-white;
        @media screen and (max-width: 767px) {
          padding: 2px 7px;
          background-color: $color-white;
          color: $color-grey-2;
          border-radius: 50px;
          letter-spacing: -0.5px;
        }
      }
    }
  }
}

.logo-footer {
  max-width: 60% !important;
  @media screen and (max-width: 767px) {
    width: 150px !important;
  }
}

.legal-nav {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: $space-2;
    @media screen and (max-width: 767px) {
      justify-content: center;
      gap: $space-1;
      width: 100%;
    }
    li.menu-item {
      a {
        color: $color-white;
        font-size: $font-size-small;
      }
    }
  }
}
