.woo-bloques {
  padding: $space-1 0 0 0;
  background-color: $color-grey-6;

  // Checkout
  .wp-block-woocommerce-checkout {
  }

  .wc-block-checkout {
    // Columna Izquierda
    &__main {
    }
    &__form {
    }

    .wp-block-woocommerce-checkout-express-payment-block {
      display: none;
      visibility: hidden;
    }

    .wc-block-components-checkout-step {
      width: 100%;
      padding: $space-1 $space-1 $space-1 3rem;
      background-color: $color-white;
      border-radius: $border-radius;
      margin-bottom: $space-1;
      @media screen and (max-width: 575px) {
        padding: $space-1 !important;
      }
      &--with-step-number {
      }

      &__heading {
        margin: calc($font-size * 0.5) 0;
      }

      &__title {
        font-size: $font-size;
        color: $color-primary;
      }

      &__container {
        &::after {
          border-left: none;
        }
      }

      &__content {
      }
    }

    &__contact-fields {
    }

    &__billing-fields {
    }

    &__shipping-option {
    }

    &__payment-method {
    }

    &__order-notes {
      padding-left: 3rem !important;
    }

    &__add-note {
      margin: calc($font-size * 0.5) 0;
    }

    &__terms {
      span {
        font-size: $font-size-small;
      }
    }

    &__actions {
    }

    .wc-block-components-radio-control-accordion-content {
      font-size: $font-size-small;
      padding-left: 3.25rem;
      p {
        font-size: $font-size-small;
        text-align: left !important;
        margin-bottom: $font-size !important;
        &:nth-last-child(1) {
          margin-bottom: 0 !important;
        }
      }
    }

    .wc-block-components-checkbox .wc-block-components-checkbox__mark {
      left: 3px;
    }

    // Columna Derecha
    &__sidebar {
      margin-top: 0 !important;
    }

    .wp-block-woocommerce-checkout-order-summary-block {
      // width: 100%;
      // padding: $space-1;
      // background-color: $color-white;
      // border-radius: $border-radius;
    }

    .wc-block-components-totals-wrapper {
      .wc-block-components-totals-footer-item-tax {
        margin-bottom: 0 !important;
      }
    }

    .wp-block-woocommerce-checkout-order-summary-cart-items-block {
      border-top: none;
      padding-top: none;
    }

    .wp-block-woocommerce-checkout-order-summary-coupon-form-block {
      .wc-block-components-totals-coupon__form {
        align-items: center;
      }
    }

    .wp-block-woocommerce-checkout-order-summary-subtotal-block {
    }

    .wp-block-woocommerce-checkout-order-summary-fee-block {
    }

    .wp-block-woocommerce-checkout-order-summary-discount-block {
    }
  }

  // Carrito

  .wp-block-woocommerce-cart {
    .is-large.wc-block-cart {
      margin-bottom: 0;
      padding-bottom: 3rem;
    }
  }

  .wc-block-cart {
    &__main {
    }

    .wc-block-cart-items {
      width: 100%;
      padding: $space-1;
      background-color: $color-white !important;
      border-radius: $border-radius;
      margin-bottom: 0;
      &__header {
        th {
          padding: $space-1 $space-1 $font-size $space-1 !important;
        }
      }
      tbody td {
        // border-top: none;
      }
    }

    .wc-block-cart-item {
      &__image {
        padding-left: $space-1 !important;
      }
      &__total {
        padding-right: $space-1 !important;
      }
    }

    .wp-block-woocommerce-cart-order-summary-block {
      // width: 100%;
      // padding: $space-1;
      // background-color: $color-white !important;
      // border-radius: $border-radius;
      // margin-bottom: 0;
    }

    .wp-block-woocommerce-cart-order-summary-heading-block {
      padding: 0 0 $font-size 0 !important;
    }

    .wc-block-components-totals-coupon__form {
      align-items: center;
    }

    .wp-block-woocommerce-cart-order-summary-shipping-block {
      .wc-block-components-totals-shipping__fieldset {
        .wc-block-components-radio-control {
          .wc-block-components-radio-control__input {
            @media screen and (max-width: 767px) {
              left: 0;
            }
          }
        }
      }
    }

    &.is-small,
    &.is-mobile {
      .wp-block-woocommerce-cart-order-summary-shipping-block {
        .wc-block-components-totals-shipping__fieldset {
          .wc-block-components-radio-control {
            label.wc-block-components-radio-control__option {
              padding: 0 0 0 2.25rem;
            }
          }
        }
      }
    }

    .wc-block-components-totals-wrapper {
      .wc-block-components-totals-footer-item-tax {
        font-size: $font-size-smaller;
        margin-bottom: 0 !important;
      }
    }

    ul.wc-block-components-express-payment__event-buttons {
      margin-bottom: 0 !important;
      .paypal-buttons iframe {
        z-index: 1 !important;
      }
    }
  }

  .wp-block-woocommerce-empty-cart-block {
    p a {
    }

    .wc-block-grid__products {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-auto-rows: 1fr;
      gap: $space-3 $space-1;
      margin-bottom: 0;
      padding-bottom: $space-2;
      @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .wc-block-grid__product {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .wc-block-grid__product-image {
          img {
            border-radius: $border-radius;
          }
        }
        .wc-block-grid__product-price {
          margin-top: auto;
        }
        .wp-block-button {
          margin: 0;
          .add_to_cart_button {
            width: 100%;
            display: block;
            padding: 0.6rem 0.6rem;
            font-size: $font-size-small;
            font-weight: $font-weight-medium;
            margin-top: 0.5rem;
            background-color: $color-primary;
            color: $color-white;
            text-align: center;
            border-radius: 7px;
            &:hover {
              color: $color-white;
            }
            @media (max-width: 575px) {
              bottom: 0.5rem;
              right: 0.5rem;
              font-size: 0.85rem;
              font-weight: $font-weight-normal;
            }
          }
        }
      }
    }
  }

  // Inputs
  .wc-block-checkout__billing-fields,
  .wc-block-checkout__shipping-fields {
    .wc-block-components-address-form {
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: space-between !important;
      gap: $font-size !important;

      @media screen and (max-width: 575px) {
        gap: 0.5rem !important;
        & > div {
          flex: 0 0 100%;
        }
      }
    }
  }

  .wc-block-components-text-input,
  .wc-block-components-country-input,
  .wc-block-components-state-input {
    margin-top: 0 !important;
    input,
    label {
      font-size: $font-size-small !important;
    }
    label {
      top: 1px !important;
    }
  }

  .wc-block-components-textarea {
    font-size: $font-size-small !important;
  }

  // Botones
  .wc-block-components-checkout-return-to-cart-button,
  .wc-block-components-checkout-place-order-button,
  .wc-block-components-totals-coupon__button,
  .wc-block-cart__submit-button {
    @include input-buttons;
  }

  .wc-block-components-checkout-return-to-cart-button {
    background-color: transparent;
    color: $color-grey-3;
    font-size: $font-size-small;
    &:hover {
      box-shadow: none;
    }
  }

  .wc-block-components-checkout-place-order-button,
  .wc-block-components-totals-coupon__button,
  .wc-block-cart__submit-button {
    background-color: $color-primary;
    color: $color-background;
    &:hover {
      background-color: darken($color-primary, 10%);
      color: $color-background;
    }
  }
}

#ppcp-checkout-paylater-messages,
#ppcp-cart-paylater-messages,
.ppcp-messages {
  margin-top: $font-size;
}

.thankyou-productos {
  padding: $space-3 0;
  background-color: $color-white;
}
