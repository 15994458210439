.hero {
  height: 800px;
  background-color: #ebeae8;
  position: relative;
  @media screen and (max-width: 575px) {
    height: 350px;
  }

  &__overlay {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.2);
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.3);
      //display: none;
    }
  }

  &__bg {
    background: url("../assets/site/hero-cover.jpeg") no-repeat top center
      #ebeae8;
    background-size: cover;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    @media screen and (min-width: 1419px) {
      // max-width: 1419px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__link {
    position: absolute;
    display: inline-block;
    margin-bottom: 3rem;
    z-index: 3;
    bottom: 0rem;
    left: 48%;
  }
  &__arrow {
    width: 50px;
    height: auto;
    color: $color-white;
    filter: invert(1);
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
    }
  }
  &__content {
    margin-top: 5rem;
    text-align: center;
    @media screen and (min-width: 768px) {
      width: 75%;
    }
    @media screen and (min-width: 991px) {
      width: 65%;
    }
    @media screen and (max-width: 767px) {
      width: 70%;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
      width: 100%;
    }
    h2 {
    }
    h3 {
    }
  }
  &__titulo {
    color: $color-white;
    font-weight: bold;
    font-size: 3.4rem;
    @media screen and (max-width: 1199px) {
      font-size: 2.6rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.8rem !important;
    }
  }
  &__subtitulo {
    color: $color-white;
    border-radius: 3px;
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }
  &__actions {
    // .boton--dark {
    //   @media screen and (max-width: 991px) {
    //     background-color: $color-principal !important;
    //   }
    // }
  }
}
