.zoom-social-icons-list {
  .zoom-social_icons-list__item {
  }
  .zoom-social_icons-list__link {
    .zoom-social_icons-list-span {
      color: $color-grey-3 !important;
      transition: $trans;
      background: $color-white !important;
      &:hover {
        transform: scale(1.2) !important;
      }

      &::before {
        color: $color-grey-3 !important;
      }
      &:hover::before {
        background-color: $color-background !important;
      }
    }
  }
}
